import {
  BusinessOutlined,
  EmailOutlined,
  PaidOutlined,
  PersonOutlineOutlined,
  PublicOutlined,
} from "@mui/icons-material";
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Link,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import NextButton from "../../../components/buttons/NextButton";
import TextButton from "../../../components/buttons/TextButton";
import { countries } from "../../../components/inputs/CountryInput";
import ActionsFooter from "../../../components/layout/ActionsFooter";
import ContentHeader from "../../../components/layout/ContentHeader";

const ResumeStep = ({
  onPrev,
  name,
  lastname,
  email,
  companyName,
  currency,
  country,
  language,
  onCreate = () => {},
}) => {
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [acceptedEmailNotifications, setAcceptedEmailNotifications] = useState(true);

  return (
    <>
      <ContentHeader
        title={`Tudo pronto, ${name}.`}
        subtitle="Verifique suas informações e aceite os termos"
      />
      <Divider />
      <ListItem divider disableGutters>
        <ListItemIcon>
          <PersonOutlineOutlined fontSize="small" />
        </ListItemIcon>
        <ListItemText primary={`${name} ${lastname || ""}`} />
      </ListItem>
      <ListItem divider disableGutters>
        <ListItemIcon>
          <EmailOutlined fontSize="small" />
        </ListItemIcon>
        <ListItemText primary={email} />
      </ListItem>
      <ListItem divider disableGutters>
        <ListItemIcon>
          <BusinessOutlined fontSize="small" />
        </ListItemIcon>
        <ListItemText primary={companyName} />
      </ListItem>
      <ListItem divider disableGutters>
        <ListItemIcon>
          <PublicOutlined fontSize="small" />
        </ListItemIcon>
        <ListItemText
          primary={
            (countries.find((item) => item.code === country)?.label || "") + ` (${language})`
          }
        />
      </ListItem>
      <ListItem divider disableGutters>
        <ListItemIcon>
          <PaidOutlined fontSize="small" />
        </ListItemIcon>
        <ListItemText primary={currency} />
      </ListItem>
      <FormControlLabel
        sx={{ mt: 3 }}
        control={
          <Checkbox checked={acceptedTerms} onClick={() => setAcceptedTerms(!acceptedTerms)} />
        }
        label={
          <Box ml={1}>
            <Typography component={"div"} variant="body2" fontWeight={"600"}>
              Eu li e concordo com os{" "}
              <Link
                onClick={(e) => e.stopPropagation()}
                href="https://notepay.com/legal/terms"
                target="_blank"
                color={"primary.main"}
                variant="inherit"
              >
                Termos de uso
              </Link>{" "}
              e a{" "}
              <Link
                onClick={(e) => e.stopPropagation()}
                href="https://notepay.com/legal/privacy"
                target="_blank"
                color={"primary.main"}
                variant="inherit"
              >
                Política de privacidade
              </Link>
            </Typography>
          </Box>
        }
      />
      <FormControlLabel
        sx={{ mt: 2 }}
        control={
          <Checkbox
            checked={acceptedEmailNotifications}
            onClick={() => setAcceptedEmailNotifications(!acceptedEmailNotifications)}
          />
        }
        label={
          <Box ml={1}>
            <Typography variant="body2" fontWeight={"600"}>
              Concordo em receber e-mails com novidades e promoções.
            </Typography>
          </Box>
        }
      />
      <ActionsFooter
        rightContent={
          <Tooltip
            disableFocusListener={acceptedTerms}
            disableHoverListener={acceptedTerms}
            title="Aceite os termos de política e privacidade antes de prosseguir"
          >
            <span>
              <NextButton disabled={!acceptedTerms} type="button" onClick={onCreate}>
                Criar minha conta
              </NextButton>
            </span>
          </Tooltip>
        }
        leftContent={<TextButton onClick={onPrev}>Anterior</TextButton>}
      />
    </>
  );
};

export default ResumeStep;
