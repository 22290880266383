import { InfoOutlined } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";

function ModalErrorBase({ open, onClose, title, subtitle = "" }) {
  return (
    open && (
      <Dialog
        fullWidth
        maxWidth="xs"
        open={open}
        PaperProps={{
          sx: { maxWidth: 500 },
        }}
        onClose={onClose}
      >
        <DialogTitle display={"flex"} alignItems="center">
          <InfoOutlined fontSize="large" sx={{ mr: 2 }} color="error" />
          <span>{title}</span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{subtitle}</DialogContentText>
        </DialogContent>
        <DialogActions sx={{ py: 2 }} onClick={onClose}>
          <Button color="inherit">Fechar</Button>
        </DialogActions>
      </Dialog>
    )
  );
}

export default ModalErrorBase;
