import { ThemeProvider } from "@mui/material";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import Router from "./Router";
import theme from "./theme";

function App() {
  return (
    <ThemeProvider theme={theme()}>
      <div className="App">
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </div>
    </ThemeProvider>
  );
}

export default App;
