import { CheckCircleOutlineOutlined, Close } from "@mui/icons-material";
import { Box, Button, IconButton, Snackbar, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AuthAPIGateway } from "../../api/auth";
import NextButton from "../../components/buttons/NextButton";
import TextButton from "../../components/buttons/TextButton";
import TextInput from "../../components/inputs/text-input";
import ActionsFooter from "../../components/layout/ActionsFooter";
import Content from "../../components/layout/Content";
import ContentHeader from "../../components/layout/ContentHeader";
import { useAsync } from "../../hooks/useAsync";

const ConfirmCreationEmail = () => {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState({
    name: "",
    companyName: "",
  });

  const [completed, setCompleted] = useState(false);
  const [searchParams] = useSearchParams();

  const fromReactNative = (searchParams.get("fromReactNative") || "") === "1";

  const email = searchParams.get("email") || "";

  const { error, runAsync, setError } = useAsync(
    async () => await AuthAPIGateway.checkCreationEmail(email, code)
  );

  const [snackbar, setSnackbar] = useState(null);
  const [code, setCode] = useState(searchParams.get("code") || "");

  const handleSendAgain = async () => {
    try {
      setSnackbar(`Código enviado para "${email}"`);
      await AuthAPIGateway.sendCreationEmail(email);
    } catch (error) {
      setSnackbar(`Erro ao enviar código. Tente novamente`);
    }
  };

  const handleCheckEmail = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (!code || code.length < 5) {
      return;
    }
    runAsync(
      (res) => {
        setCompleted(true);
        setUserInfo({
          companyName: res.data?.account?.name,
          name: res.data?.user?.name,
        });
      },
      () => setCompleted(false)
    );
  };

  useEffect(() => {
    if (code && code.length === 5) {
      handleCheckEmail();
    }
  }, [code]);

  return (
    <Content>
      {completed ? (
        <>
          <ContentHeader
            iconColor={"success"}
            Icon={CheckCircleOutlineOutlined}
            title={`Conta criada com sucesso.`}
            subtitle={`Parabéns, ${userInfo.name}! Sua conta para a empresa "${userInfo.companyName}" foi criada com sucesso. Volte ao login para explorar todos os recursos da plataforma.`}
          />
          <ActionsFooter
            rightContent={
              <NextButton
                type="button"
                onClick={() => {
                  if (fromReactNative) {
                    const reactNativeMessage = {
                      type: "create-acount",
                      payload: {
                        email,
                      },
                    };
                    window.ReactNativeWebView?.postMessage(
                      JSON.stringify(reactNativeMessage)
                    );
                  } else {
                    navigate(`/?email=${email}&step=1`, { replace: true });
                  }
                }}
              >
                Voltar para o login
              </NextButton>
            }
          />
        </>
      ) : (
        <>
          <ContentHeader
            title="Confirme o seu e-mail"
            subtitle={
              <Typography variant="inherit">
                Um código de confirmação foi enviado para o e-mail <br />
                <Typography
                  fontWeight={"500"}
                  component={"span"}
                  color={"text.primary"}
                  variant="inherit"
                >
                  {email}
                </Typography>
              </Typography>
            }
          />
          <form onSubmit={handleCheckEmail}>
            <TextInput
              value={code}
              onChange={setCode}
              autoFocus
              error={Boolean(error)}
              maxLength={5}
              margin={"normal"}
              clearError={() => setError("")}
              helperText={error || ""}
              placeholder={"00000"}
              inputSx={{
                fontSize: "1.6rem",
                "& input": {
                  textAlign: "center",
                },
              }}
            />
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Typography variant="body2" color={"text.secondary"}>
                Não recebeu o código?
              </Typography>
              <Button size="small" onClick={handleSendAgain}>
                Enviar novamente
              </Button>
            </Box>
            <ActionsFooter
              leftContent={
                <TextButton
                  onClick={() =>
                    navigate(`/?email=${email}`, { replace: true })
                  }
                >
                  Voltrar para login
                </TextButton>
              }
              rightContent={<NextButton>Confirmar e-mail</NextButton>}
            />
          </form>
        </>
      )}
      {Boolean(snackbar) && (
        <Snackbar
          open={Boolean(snackbar)}
          anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
          autoHideDuration={3000}
          onClose={() => setSnackbar(null)}
          message={snackbar}
          action={
            <IconButton onClick={() => setSnackbar(null)} color="inherit">
              <Close />
            </IconButton>
          }
        />
      )}
    </Content>
  );
};

export default ConfirmCreationEmail;
