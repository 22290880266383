import { Box } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthAPIGateway } from "../../api/auth";
import { APP_BASE_URL } from "../../api/urls";
import NextButton from "../../components/buttons/NextButton";
import TextButton from "../../components/buttons/TextButton";
import PasswordInput from "../../components/inputs/PasswordInput";
import { useAsync } from "../../hooks/useAsync";

function PasswordStep({ email, redirect }) {
  const navigate = useNavigate();

  const [value, setValue] = useState("");

  const { error, setError, runAsync } = useAsync(
    async () => await AuthAPIGateway.login(email, value)
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!value) {
      setError("Insira uma senha");
      return;
    }
    runAsync(
      (response) => {
        const { user } = response;
        window.localStorage.removeItem("SSODomain");
        window.localStorage.setItem("email", email);
        if (redirect && redirect !== "null" && redirect !== "undefined") {
          return window.location.replace(redirect);
        }
        window.location.replace(APP_BASE_URL);
      },
      () => {},
      "Senha inválida"
    );
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <PasswordInput
          autoFocus
          error={error}
          value={value}
          onChange={setValue}
          clearError={() => setError("")}
        />
        <Box mt={3} display={"flex"} alignItems={"center"}>
          <TextButton
            onClick={() => navigate(`forgot-password?email=${email}`)}
          >
            Esqueci minha senha
          </TextButton>
          <Box flex={1} />
          <NextButton type="submit">Avançar</NextButton>
        </Box>
      </form>
    </>
  );
}

export default PasswordStep;
