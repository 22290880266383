import { KeyOutlined } from "@mui/icons-material";
import { Box, Button, Divider, Link, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import validator from "validator";
import { AuthAPIGateway } from "../../api/auth";
import NextButton from "../../components/buttons/NextButton";
import TextButton from "../../components/buttons/TextButton";
import EmailInput from "../../components/inputs/EmailInput";
import ActionsFooter from "../../components/layout/ActionsFooter";
import { useAuth } from "../../context/AuthContext";

function EmailStep({ email, onNext, onChangeEmail, redirect }) {
  const navigate = useNavigate();

  const { setLoading } = useAuth();

  const [value, setValue] = useState(email || "");

  const [error, setError] = useState(null);
  const [haveToConfirmEmail, setHaveToConfirmEmail] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!value) {
      setError("Insira um e-mail");
      return;
    }
    setLoading(true);
    onChangeEmail(value);
    try {
      const { data } = await AuthAPIGateway.checkEmail(value);
      if (data?.inUse) {
        if (data?.haveToConfirmEmail) {
          setError("E-mail já está registrado e está aguardando confirmação.");
          setHaveToConfirmEmail(true);
          return;
        } else {
          setHaveToConfirmEmail(false);
        }
      } else {
        setError("E-mail inválido");
        return;
      }
      onNext(value);
    } catch (error) {
      setError("Erro inesperado ao validar e-mail. Tente novamente");
    } finally {
      setLoading(false);
    }
  };

  const handleClickLoginSSO = () => {
    let queryParams = [];

    // Verifica se o valor é um e-mail válido
    const domain = value.split('@')[1];
    if (validator.isEmail(value) && domain) {
      queryParams.push(`domain=${encodeURIComponent(domain)}`);
    }

    // Adiciona o parâmetro de redirecionamento, se fornecido
    if (redirect) {
      queryParams.push(`redirect=${encodeURIComponent(redirect)}`);
    }

    // Constrói o URL com base nos parâmetros de busca
    let url = "/login-sso";
    if (queryParams.length > 0) {
      url += "?" + queryParams.join("&");
    }

    // Navega para o URL gerado
    navigate(url);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <EmailInput
          autoFocus
          value={value}
          onChange={setValue}
          clearError={() => setError("")}
          error={
            error && (
              <Typography component={"span"} variant="inherit">
                {error}
                {haveToConfirmEmail && (
                  <Typography component={"span"} variant="inherit" ml={0.5}>
                    Para confirmação{" "}
                    <Link
                      component={Button}
                      size="small"
                      onClick={() =>
                        navigate(`/confirm-creation-email?email=${email}`)
                      }
                      sx={{ height: 10 }}
                      variant="inherit"
                    >
                      Clique aqui
                    </Link>
                  </Typography>
                )}
              </Typography>
            )
          }
        />
        <ActionsFooter
          leftContent={
            <TextButton onClick={() => navigate("create-account")}>
              Criar uma conta
            </TextButton>
          }
          rightContent={<NextButton />}
        />
      </form>
      <Divider sx={{ my: 4 }} />
      <Box display={"flex"} justifyContent={"center"}>
        <Button
          startIcon={<KeyOutlined />}
          sx={{ borderRadius: 100 }}
          color="inherit"
          variant="outlined"
          onClick={handleClickLoginSSO}
        >
          Login único (SSO)
        </Button>
      </Box>
    </>
  );
}

export default EmailStep;
