import { Typography } from "@mui/material";
import React, { memo } from "react";

function Title({ children, sx }) {
  return (
    <Typography
      textAlign={"center"}
      gutterBottom
      variant="h5"
      mt={0.3}
      fontWeight={"500"}
      sx={{ opacity: 0.9, ...sx }}
      fontSize={"1.7rem"}
      component={"div"}
    >
      {children}
    </Typography>
  );
}

export default memo(Title);
