import { MenuItem } from "@mui/material";
import React, { useState } from "react";
import NextButton from "../../../components/buttons/NextButton";
import TextButton from "../../../components/buttons/TextButton";
import CountrySelect from "../../../components/inputs/CountryInput";
import PhoneInput from "../../../components/inputs/PhoneInput";
import SelectInput from "../../../components/inputs/SelectInput";
import TextInput from "../../../components/inputs/text-input";
import ActionsFooter from "../../../components/layout/ActionsFooter";

const CompanyInfoStep = ({ initialValues = {}, onSubmit = () => {}, onPrev = () => {} }) => {
  const [companyName, setCompanyName] = useState(initialValues.companyName || "");
  const [country, setCountry] = useState(initialValues.country || "BR");

  const [currency, setCurrency] = useState(initialValues.currency || "BRL");
  const [language, setLanguage] = useState(initialValues.language || "PT");
  const [usersNum, setUsersNum] = useState(initialValues.usersNum || "");
  const [hasSap, setHasSap] = useState(initialValues.hasSap || "");
  const [phone, setPhone] = useState(initialValues.phone || "");

  const [errors, setErrors] = useState({
    companyName: null,
    country: null,
    currency: null,
    language: null,
    phone: null,
    usersNum: null,
    hasSap: null,
  });

  const setError = (field, value) => {
    setErrors((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const inavlidFiels = {
      companyName: !Boolean(companyName.trim()),
      country: !Boolean(country.trim()),
      currency: !Boolean(currency.trim()),
      language: !Boolean(language.trim()),
    };

    let ok = true;

    Object.keys(inavlidFiels).forEach((field) => {
      if (inavlidFiels[field]) {
        setError(field, "Campo obrigatório");
        ok = false;
      }
    });
    if (!ok) return;
    onSubmit({ companyName, country, currency, language, phone, hasSap, usersNum });
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextInput
        autoFocus
        value={companyName}
        onFocus={() => setError("companyName", null)}
        onChange={setCompanyName}
        margin={"normal"}
        label={"Nome da empresa"}
        helperText={errors.companyName || ""}
        error={Boolean(errors.companyName)}
        required
      />
      <CountrySelect value={country} onChange={setCountry} />
      <SelectInput
        label="Moeda"
        value={currency}
        required
        onFocus={() => setError("currency", null)}
        onChange={setCurrency}
        helperText={errors.currency || ""}
        error={Boolean(errors.currency)}
      >
        <MenuItem value={"BRL"}>BRL</MenuItem>
        {/* <MenuItem disabled value={"USD"}>
          USD
          <Box flex={1} />
          Em breve
        </MenuItem>
        <MenuItem disabled value={"EUR"}>
          EUR
          <Box flex={1} />
          Em breve
        </MenuItem> */}
      </SelectInput>
      <SelectInput
        label="Idioma"
        value={language}
        required
        onFocus={() => setError("language", null)}
        onChange={setLanguage}
        helperText={errors.language || ""}
        error={Boolean(errors.language)}
      >
        <MenuItem value={"PT"}>Português (Brasil)</MenuItem>
        {/* <MenuItem value={"EN"}>Inglês (Estados Unidos)</MenuItem>
        <MenuItem value={"ES"}>Espanhol</MenuItem> */}
      </SelectInput>
      <TextInput
        label={"Telefone para contato (opcional)"}
        margin={"normal"}
        value={phone}
        onChange={setPhone}
        type="tel"
        inputComponent={PhoneInput}
      />
      <SelectInput
        label="Número de colaboradores (opcional)"
        value={usersNum}
        onChange={setUsersNum}
      >
        <MenuItem value={"1"}>1 - 10</MenuItem>
        <MenuItem value={"2"}>11 - 30</MenuItem>
        <MenuItem value={"3"}>31 - 50</MenuItem>
        <MenuItem value={"4"}>Acima de 50</MenuItem>
      </SelectInput>
      <SelectInput label="Sua empresa utiliza o SAP?" value={hasSap} onChange={setHasSap}>
        <MenuItem value={"S"}>Sim</MenuItem>
        <MenuItem value={"N"}>Não</MenuItem>
      </SelectInput>
      <ActionsFooter
        rightContent={<NextButton />}
        leftContent={<TextButton onClick={onPrev}>Anterior</TextButton>}
      />
    </form>
  );
};

export default CompanyInfoStep;
