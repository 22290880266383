import { Close } from "@mui/icons-material";
import { Box, IconButton, Snackbar, Typography } from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import { AuthAPIGateway } from "../../../api/auth";
import NextButton from "../../../components/buttons/NextButton";
import TextButton from "../../../components/buttons/TextButton";
import TextInput from "../../../components/inputs/text-input";
import ActionsFooter from "../../../components/layout/ActionsFooter";
import ContentHeader from "../../../components/layout/ContentHeader";
import { useAsync } from "../../../hooks/useAsync";

function ConfirmCodeStep({ email, onNext }) {
  const [cod, setCod] = useState("");

  const [snackbar, setSnackbar] = useState(null);

  const { error, setError, runAsync } = useAsync(
    async () => await AuthAPIGateway.checkCodeForgotPassword(cod, email)
  );

  const { error: resendError, runAsync: runResend } = useAsync(
    async () => await AuthAPIGateway.sendCodeToEmailForgotPassword(email)
  );

  const handleCheckCode = () => {
    if (!cod) {
      setError("Insira o código");
      return;
    }
    runAsync(() => onNext(cod), setError);
  };

  useEffect(() => {
    if (cod.length === 5) {
      handleCheckCode();
    }
  }, [cod]);

  const resend = async () => {
    await runResend();
    setSnackbar(true);
  };

  return (
    <>
      <ContentHeader
        title={"Código enviado"}
        subtitle={
          <Typography variant="inherit">
            Insira o código de verficação enviado para <br />{" "}
            <strong>{email}</strong>
          </Typography>
        }
      />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleCheckCode();
        }}
      >
        <Box mt={3} display={"flex"} justifyContent={"center"}>
          <TextInput
            value={cod}
            onChange={setCod}
            autoFocus
            error={Boolean(error)}
            maxLength={5}
            width={300}
            clearError={() => setError("")}
            helperText={error || ""}
            placeholder={"00000"}
            inputSx={{
              fontSize: "1.6rem",
              "& input": {
                textAlign: "center",
              },
            }}
          />
        </Box>
        <ActionsFooter
          leftContent={
            <TextButton onClick={resend}>Enviar novamente</TextButton>
          }
          rightContent={<NextButton>Avançar</NextButton>}
        />
      </form>

      {snackbar && (
        <Snackbar
          open={Boolean(snackbar)}
          onClose={() => setSnackbar(null)}
          message={
            resend
              ? "Não foi possível enviar o código. Tente novamente"
              : "Código enviado"
          }
          anchorOrigin={{
            horizontal: "right",
            vertical: "bottom",
          }}
          action={
            <IconButton
              sx={{ color: "#FFF" }}
              onClick={() => setSnackbar(null)}
            >
              <Close />
            </IconButton>
          }
          ContentProps={{
            sx: {
              fontWeight: "600",
              bgcolor: resendError ? "error.main" : undefined,
            },
          }}
        />
      )}
    </>
  );
}

export default memo(ConfirmCodeStep);
