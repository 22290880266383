import { Box } from "@mui/material";
import React, { memo } from "react";
import Logo from "../../assets/logo/logo.png";

function LogoHeader(props) {
  return (
    <Box mt={5} mb={1} display={"flex"} justifyContent={"center"}>
      <img width={"100px"} src={Logo} />
    </Box>
  );
}

export default memo(LogoHeader);
