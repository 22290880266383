import { forwardRef } from "react";
import { IMaskInput } from "react-imask";

const PhoneInput = forwardRef(function PhoneInput(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="+55 (00) 00000-0000"
      prefix="55"
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

export default PhoneInput;
