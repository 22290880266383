import {
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Link,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import validator from "validator";
import { AuthAPIGateway } from "../../../api/auth";
import NextButton from "../../../components/buttons/NextButton";
import TextButton from "../../../components/buttons/TextButton";
import EmailInput from "../../../components/inputs/EmailInput";
import PasswordInput from "../../../components/inputs/PasswordInput";
import ActionsFooter from "../../../components/layout/ActionsFooter";
import { useAuth } from "../../../context/AuthContext";
import { passwordRuleText } from "../../../utils/texts";

const AccessStep = ({
  onSubmit = () => {},
  initialEmail,
  onPrev,
  navigate,
}) => {
  const emailRef = useRef();
  const pass1 = useRef();
  const pass2 = useRef();

  const { setLoading } = useAuth();

  const [showPass, setShowPass] = useState(false);
  const [email, setEmail] = useState(initialEmail || "");
  const [pass, setPass] = useState("");
  const [checkPass, setCheckPass] = useState("");

  const [passError, setPassError] = useState(null);
  const [checkPassError, setCheckPassError] = useState(null);

  const [errorEmail, setErrorEmail] = useState(null);
  const [haveToConfirmEmail, setHaveToConfirmEmail] = useState(false);

  useEffect(() => {
    setEmail(initialEmail || "");
  }, [initialEmail]);

  const handleCheckEmail = async () => {
    setLoading(true);
    try {
      const { data } = await AuthAPIGateway.checkEmail(email);
      if (data?.inUse) {
        if (data?.haveToConfirmEmail) {
          setErrorEmail(
            "E-mail já está registrado e está aguardando confirmação."
          );
          setHaveToConfirmEmail(true);
          emailRef?.current?.focus();
          return;
        } else {
          setHaveToConfirmEmail(false);
        }
        setErrorEmail("E-mail já está registrado. Tente outro");
        emailRef?.current?.focus();
        return false;
      } else {
        setHaveToConfirmEmail(false);
        setErrorEmail("");
        return true;
      }
    } catch (error) {
      setErrorEmail(
        error?.response?.data?.message || "Erro inesperado ao validar e-mail"
      );
      emailRef?.current?.focus();
      return false;
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !validator.isStrongPassword(pass, {
        minLength: 8,
        minUppercase: 1,
        minSymbols: 1,
        minNumbers: 0,
      })
    ) {
      setPassError(passwordRuleText);
      setCheckPass("");
      pass1.current?.focus();
      return;
    }
    if (pass.trim() !== checkPass.trim()) {
      setCheckPassError("As senhas não são iguais. Tente novamente");
      setCheckPass("");
      pass2.current?.focus();
      return;
    }
    const ok = await handleCheckEmail();
    if (ok) {
      onSubmit(email, pass);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <EmailInput
        inputRef={emailRef}
        autoFocus
        value={email}
        onChange={setEmail}
        error={
          errorEmail && (
            <Typography component={"span"} variant="inherit">
              {errorEmail}
              {haveToConfirmEmail && (
                <Typography component={"span"} variant="inherit" ml={0.5}>
                  Para confirmação{" "}
                  <Link
                    component={Button}
                    size="small"
                    onClick={() =>
                      navigate(`/confirm-creation-email?email=${email}`)
                    }
                    sx={{ height: 10 }}
                    variant="inherit"
                  >
                    Clique aqui
                  </Link>
                </Typography>
              )}
            </Typography>
          )
        }
        clearError={() => setErrorEmail("")}
      />
      <PasswordInput
        inputRef={pass1}
        clearError={() => setPassError(null)}
        error={passError}
        value={pass}
        onChange={setPass}
        label={"Senha"}
        disableVisibilityButton
        controlledType={showPass ? "text" : "password"}
      />
      {!passError && (
        <FormHelperText sx={{ fontSize: ".83rem" }}>
          {passwordRuleText}
        </FormHelperText>
      )}
      <PasswordInput
        sx={{ mt: 3 }}
        inputRef={pass2}
        clearError={() => setCheckPassError(null)}
        error={checkPassError}
        value={checkPass}
        onChange={setCheckPass}
        label={"Confirme a senha"}
        disableVisibilityButton
        controlledType={showPass ? "text" : "password"}
      />
      <FormControlLabel
        sx={{ mt: 1 }}
        label="Mostrar senhas"
        control={
          <Checkbox checked={showPass} onClick={() => setShowPass(!showPass)} />
        }
      />
      <ActionsFooter
        rightContent={<NextButton />}
        leftContent={<TextButton onClick={onPrev}>Anterior</TextButton>}
      />
    </form>
  );
};

export default AccessStep;
