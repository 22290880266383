import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { AuthAPIGateway } from "../../../api/auth";
import NextButton from "../../../components/buttons/NextButton";
import EmailInput from "../../../components/inputs/EmailInput";
import ActionsFooter from "../../../components/layout/ActionsFooter";
import ContentHeader from "../../../components/layout/ContentHeader";
import { useAsync } from "../../../hooks/useAsync";

function SendCodeStep({ email, onNext }) {
  const [emailValue, setEmailValue] = useState(email || "");

  const { error, runAsync } = useAsync(
    async () => await AuthAPIGateway.sendCodeToEmailForgotPassword(emailValue)
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    runAsync(() => onNext(emailValue));
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <ContentHeader
          title={"Esqueci a senha"}
          subtitle={
            <Box
              mt={2}
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
              gap={2}
            >
              <EmailInput
                autoFocus
                margin={"normal"}
                error={error}
                value={emailValue}
                onChange={setEmailValue}
              />

              <Typography variant="inherit">
                Enviaremos um código de verificação a este e-mail se
                corresponder a uma conta da Notepay.
              </Typography>
            </Box>
          }
        />
        <ActionsFooter rightContent={<NextButton>Avançar</NextButton>} />
      </form>
    </>
  );
}

export default SendCodeStep;
