const isStaging =
  process.env.REACT_APP_DEBUG === "true" ||
  process.env.REACT_APP_ENV === "staging";
  
const isDev = process.env.REACT_APP_ENV === "development";

export const BASE_URL =
  process.env.REACT_APP_ENV === "development"
    ? "http://localhost:5101"
    : `${window.location.origin}`;

export const API_BASE_URL =
  process.env.REACT_APP_ENV === "development"
    ? "http://localhost:5101/auth/v1"
    : `${window.location.origin}/auth/v1`;

export const APP_BASE_URL =
  process.env.REACT_APP_ENV === "development"
    ? "http://localhost:3000"
    : window.location.origin.replace("auth", "app");

export const ADMIN_BASE_URL =
  process.env.REACT_APP_ENV === "development"
    ? "http://localhost:3040"
    : window.location.origin.replace("auth", "admin");

export const WSS_URL = isDev
  ? "ws://localhost:7500"
  : isStaging
  ? "wss://ws.notepay-dev.com"
  : `wss://${window.location.hostname.replace("app", "ws")}`;
