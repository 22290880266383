import { Button } from "@mui/material";
import React from "react";

function NextButton({ type = "submit", children, disabled, onClick }) {
  return (
    <Button
      disabled={disabled}
      type={type}
      onClick={onClick}
      variant="contained"
      disableElevation
      size="large"
      sx={{
        transition: "none",
        ":hover": {
          boxShadow: 2,
        },
      }}
    >
      {children || "Avançar"}
    </Button>
  );
}

export default NextButton;
