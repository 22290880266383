import { useState } from "react";
import { useAuth } from "../context/AuthContext";

export function useAsync(asyncFunction) {
  const { setLoading } = useAuth();
  const [error, setError] = useState("");

  async function runAsync(
    onSuccess = () => {},
    onError = () => {},
    errorMessage
  ) {
    setLoading(true);
    setError(null);
    try {
      const result = await asyncFunction();
      setLoading(false);
      onSuccess(result);
      return;
    } catch (error) {
      setLoading(false);
      onError();
      if (error.code === "ERR_NETWORK") {
        setError("Falha de comunicação, verifique sua conexão com a internet");
        return;
      }
      if (errorMessage) {
        setError(errorMessage);
        return;
      }
      setError(
        error?.response?.data?.message ||
          "Erro inesperado. Tente novamente mais tarde"
      );
    }
  }

  return { error, setError, runAsync };
}
