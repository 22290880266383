import { Checkbox, FormControlLabel } from "@mui/material";
import React, { useState } from "react";
import validator from "validator";
import { AuthAPIGateway } from "../../../api/auth";
import NextButton from "../../../components/buttons/NextButton";
import PasswordInput from "../../../components/inputs/PasswordInput";
import ActionsFooter from "../../../components/layout/ActionsFooter";
import ContentHeader from "../../../components/layout/ContentHeader";
import { useAsync } from "../../../hooks/useAsync";
import { passwordRuleText } from "../../../utils/texts";

function InsertNewPass({ email, code, onNext }) {
  const [pass, setPass] = useState("");
  const [confirm, setConfirm] = useState("");

  const { error, setError, runAsync } = useAsync(
    async () => await AuthAPIGateway.changePassword(code, email, pass)
  );

  const [error1, setError1] = useState("");
  const [error2, setError2] = useState("");

  const [visible, setVisible] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!pass) {
      setError1("Insira uma senha");
      return;
    }
    if (
      !validator.isStrongPassword(pass, {
        minLength: 8,
        minUppercase: 1,
        minSymbols: 1,
        minNumbers: 0,
      })
    ) {
      setError1(passwordRuleText);
      setConfirm("");
      return;
    }
    if (pass !== confirm) {
      setError2("As senhas não correspondem");
      setConfirm("");
      return;
    }
    runAsync(
      onNext,
      () => {},
      "Erro inesperado ao tentar criar sua senha, tente novamente"
    );
  };

  return (
    <>
      <ContentHeader title="Crie sua nova senha" subtitle={passwordRuleText} />
      <form onSubmit={handleSubmit}>
        <PasswordInput
          value={pass}
          onChange={setPass}
          autoFocus
          disableVisibilityButton
          controlledType={visible ? "text" : "password"}
          error={error || error1}
          label={"Senha"}
          clearError={() => {
            setError("");
            setError1("");
          }}
        />
        <PasswordInput
          value={confirm}
          onChange={setConfirm}
          label={"Confirme a senha"}
          error={error2}
          disableVisibilityButton
          controlledType={visible ? "text" : "password"}
          clearError={() => setError2("")}
        />
        <FormControlLabel
          control={
            <Checkbox checked={visible} onClick={() => setVisible(!visible)} />
          }
          label="Mostrar senhas"
        />
        <ActionsFooter rightContent={<NextButton>Confirmar</NextButton>} />
      </form>
    </>
  );
}

export default InsertNewPass;
