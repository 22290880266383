import { Typography } from '@mui/material'
import React from 'react'

function Subtitle({ children, centered = true }) {
    return (
        <Typography component={'div'} textAlign={centered ? 'center' : 'left'} variant='body1' sx={{ opacity: .9 }}>{children}</Typography>
    )
}

export default Subtitle
