import { Box, LinearProgress } from "@mui/material";
import React from "react";
import { useAuth } from "../../context/AuthContext";
import BoxContent from "./BoxContent";
import LogoHeader from "./LogoHeader";

function Container({ children }) {
  const { loading } = useAuth();

  return (
    <Box width={"100%"} maxWidth={460}>
      <BoxContent>
        <LinearProgress sx={{ visibility: loading ? "visible" : "hidden" }} />
        <LogoHeader />
        {children}
      </BoxContent>
    </Box>
  );
}

export default Container;
