import { Box } from '@mui/material'
import React from 'react'

function LoadingOverlay() {
    return (
        <Box bgcolor={'#FFFFFF60'} position={'fixed'} left={0} top={0} zIndex={t => t.zIndex.appBar + 100} height={'100vh'} width={'100vw'} overflow={'hidden'} />
    )
}

export default LoadingOverlay
