import React, { useEffect, useRef, useState } from "react";
import NextButton from "../../../components/buttons/NextButton";
import TextButton from "../../../components/buttons/TextButton";
import TextInput from "../../../components/inputs/text-input";
import ActionsFooter from "../../../components/layout/ActionsFooter";

const NameStep = ({
  initialName,
  fromReactNative,
  initialLastname,
  onSubmit,
  onPrev,
}) => {
  const nameRef = useRef();

  const [name, setName] = useState(initialName || "");
  const [lastname, setLastname] = useState(initialLastname || "");

  useEffect(() => {
    setName(initialName);
    setLastname(initialLastname);
  }, [initialName, initialLastname]);

  const [errors, setErrors] = useState({
    name: null,
    lastname: null,
  });

  const setError = (error, value) => {
    setErrors((prev) => ({
      ...prev,
      [error]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!Boolean(name?.trim().length)) {
      setError("name", "Insira um nome");
      setName("");
      nameRef?.current?.focus();
      return;
    }
    onSubmit(name?.trim(), lastname?.trim());
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextInput
        inputRef={nameRef}
        autoFocus
        value={name}
        onFocus={() => setError("name", null)}
        onChange={setName}
        margin={"dense"}
        label={"Primeiro nome"}
        helperText={errors.name || ""}
        error={Boolean(errors.name)}
        required
      />
      <TextInput
        value={lastname}
        onChange={setLastname}
        margin={"normal"}
        label={"Último nome"}
      />
      <ActionsFooter
        rightContent={<NextButton />}
        leftContent={
          !fromReactNative && (
            <TextButton onClick={onPrev}>Voltar para login</TextButton>
          )
        }
      />
    </form>
  );
};

export default NameStep;
