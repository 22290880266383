import { ArrowBack } from "@mui/icons-material";
import { Chip, Tooltip, Typography } from "@mui/material";
import React, { memo } from "react";

function EmailChip({ editable, onClick, email, size }) {
  return (
    <Tooltip
      disableHoverListener={!editable}
      disableFocusListener={!editable}
      placement="left"
      title={
        <Typography fontWeight={"500"} fontSize={".8rem"}>
          Alterar e-mail
        </Typography>
      }
    >
      <Chip
        size={size}
        clickable={editable}
        onClick={onClick}
        icon={editable ? <ArrowBack fontSize="small" color="action" /> : null}
        sx={{
          fontWeight: "600",
          fontSize: ".9rem",
          px: 1,
          transition: "none",
          ":hover": { boxShadow: editable && 1 },
        }}
        variant="outlined"
        label={email}
      />
    </Tooltip>
  );
}

export default memo(EmailChip);
