import { FormControl, FormHelperText, InputLabel, Select } from "@mui/material";
import React from "react";

const SelectInput = ({
  value,
  onChange = () => {},
  onFocus = () => {},
  margin = "normal",
  label = "",
  children,
  required,
  helperText = "",
  error,
}) => {
  return (
    <FormControl error={error} required={required} margin={margin} fullWidth>
      <InputLabel id="select-label">{label}</InputLabel>
      <Select
        onFocus={onFocus}
        labelId="select-label"
        id="select"
        value={value || ""}
        label={label}
        onChange={(e) => onChange(e.target.value)}
      >
        {children}
      </Select>
      {helperText && (
        <FormHelperText error={error}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};

export default SelectInput;
