import { Box } from "@mui/material";
import React from "react";
import Subtitle from "../typography/Subtitle";
import Title from "../typography/Title";

function ContentHeader({ title, subtitle, Icon, iconColor }) {
  return (
    <Box mb={2} display={"flex"} flexDirection={"column"}>
      {Icon && (
        <Icon
          fontSize="large"
          color={iconColor}
          sx={{ my: 1, alignSelf: "center" }}
        />
      )}
      <Title>{title}</Title>
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
    </Box>
  );
}

export default ContentHeader;
