import React from "react";
import TextInput from "./text-input";

function EmailInput({
  clearError,
  autoFocus,
  error,
  value,
  onChange,
  inputRef,
  disabled,
}) {
  return (
    <TextInput
      disabled={disabled}
      autoFocus={autoFocus}
      error={Boolean(error)}
      helperText={error || ""}
      label={"E-mail"}
      type="email"
      margin={"normal"}
      value={value}
      onChange={onChange}
      clearError={clearError}
      required
      inputRef={inputRef}
    />
  );
}

export default EmailInput;
