import { InfoRounded } from "@mui/icons-material";
import { InputAdornment, TextField, Typography } from "@mui/material";
import React, { memo } from "react";

function Input({
  label,
  width,
  className,
  type = "text",
  LeftIcon,
  maxLength,
  RightIcon,
  size,
  placeholder,
  variant,
  helperText = "",
  onChange = () => {},
  onBlur,
  onFocus = () => {},
  required,
  inputComponent,
  multiline,
  autoFocus,
  readOnly,
  sx,
  value,
  maxRows,
  onClick,
  inputMode,
  rows,
  disabled,
  error,
  onKeyDown,
  margin,
  id,
  inputRef,
  hiddenLabel,
  clearError = () => {},
  inputSx,
}) {
  const handleChange = (e) => {
    onChange(e.target.value);
    if (error) {
      clearError();
    }
  };

  return (
    <TextField
      id={id}
      sx={{
        width: width || "100%",
        ...sx,
      }}
      margin={margin}
      inputRef={inputRef}
      onKeyDown={onKeyDown}
      disabled={disabled}
      inputMode={inputMode}
      onClick={onClick}
      inputProps={{ maxLength: maxLength, readOnly: readOnly }}
      placeholder={placeholder}
      className={className}
      autoFocus={autoFocus}
      type={type}
      required={required}
      label={label}
      size={size}
      onChange={handleChange}
      value={value}
      hiddenLabel={hiddenLabel}
      variant={variant}
      error={error}
      onFocus={onFocus}
      onBlur={onBlur}
      maxRows={maxRows}
      rows={rows}
      multiline={multiline}
      helperText={
        error && (
          <Typography
            component={"span"}
            ml={-1}
            display={"flex"}
            alignItems={"flex-start"}
            variant="inherit"
            fontWeight={"500"}
            fontSize={".83rem"}
          >
            <InfoRounded
              color="error"
              sx={{ mr: 1, mt: 0.2, fontSize: "1rem" }}
            />
            {helperText}
          </Typography>
        )
      }
      InputProps={{
        sx: inputSx,
        inputComponent: inputComponent,
        startAdornment: LeftIcon && (
          <InputAdornment position={"start"}>
            <LeftIcon fontSize={size} />
          </InputAdornment>
        ),
        endAdornment: RightIcon && (
          <InputAdornment position={"end"}>
            <RightIcon fontSize={size} />
          </InputAdornment>
        ),
      }}
    ></TextField>
  );
}
export default memo(Input);
