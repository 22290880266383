import { Collapse } from "@mui/material";
import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import EmailChip from "../../components/chips/EmailChip";
import Content from "../../components/layout/Content";
import ContentHeader from "../../components/layout/ContentHeader";
import EmailStep from "./EmailStep";
import PasswordStep from "./PasswordStep";

function Login(props) {
  document.title = "Notepay: entre ou cadastre-se";

  const [searchParams, setSearchParams] = useSearchParams({
    email: "",
    step: "0",
  });

  const emailStorage = window.localStorage.getItem("email");

  const step = searchParams.get("step") || "0";
  const email =
    searchParams.get("email") ||
    (emailStorage !== "null" ? emailStorage : "") ||
    "";
  const redirect = searchParams.get("redirect");

  useEffect(() => {
    if (step && (parseInt(step) > 1 || (step === "1" && !email))) {
      searchParams.set("step", "0");
      setSearchParams(searchParams);
    }
  }, []);

  return (
    <Content>
      <Collapse unmountOnExit in={step === "0"}>
        <ContentHeader
          title={"Fazer login"}
          subtitle={"Insira o e-mail da sua conta"}
        />
        <EmailStep
          email={email}
          redirect={redirect}
          onChangeEmail={(newEmail) => {
            searchParams.set("email", newEmail);
            setSearchParams(searchParams);
          }}
          onNext={(value) => {
            searchParams.set("email", value);
            searchParams.set("step", "1");
            setSearchParams(searchParams);
          }}
        />
      </Collapse>
      <Collapse unmountOnExit in={step === "1"}>
        <ContentHeader
          title={`Olá`}
          subtitle={
            <EmailChip
              editable
              onClick={() => {
                searchParams.set("step", "0");
                setSearchParams(searchParams);
              }}
              email={email}
            />
          }
        />
        <PasswordStep email={email} redirect={redirect} />
      </Collapse>
    </Content>
  );
}

export default Login;
